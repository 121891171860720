ol {
    margin: 0;
    padding: 0
}

table td,
table th {
    padding: 0
}

.privacy_c6 {
    line-height: 2.5;
    border-right-style: solid;
    padding: 18pt 18pt 18pt 18pt;
    border-bottom-color: #eeeeee;
    border-top-width: 1.5pt;
    border-right-width: 1.5pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #eeeeee;
    border-left-width: 0pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1.5pt;
    width: 210.8pt;
    border-top-color: #eeeeee;
    border-bottom-style: solid
}

.privacy_c4 {
    border-right-style: solid;
    padding: 8pt 8pt 8pt 8pt;
    border-bottom-color: #eeeeee;
    border-top-width: 1.5pt;
    border-right-width: 0pt;
    border-left-color: #eeeeee;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1.5pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1.5pt;
    width: 240.5pt;
    border-top-color: #eeeeee;
    border-bottom-style: solid
}

.privacy_c2 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 10pt;
    font-family: "Poppins";
    font-style: normal
}

.privacy_c16 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: "Times";
    font-style: normal
}

.privacy_c3 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: "Poppins";
    font-style: normal
}

.privacy_c1 {
    background-color: #ffffff;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.5;
    orphans: 2;
    widows: 2;
    text-align: left
}

.privacy_c7 {
    color: #1fd2db;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 10pt;
    font-family: "Poppins";
    font-style: normal
}

.privacy_c13 {
    color: #00b1d1;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 10pt;
    font-family: "Poppins";
    font-style: normal
}

.privacy_c11 {
    color: #1fd2db;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 16pt;
    font-family: "Poppins";
    font-style: normal
}

.privacy_c0 {
    background-color: #ffffff;
    padding-top: 0pt;
    padding-bottom: 12pt;
    line-height: 2.5;
    orphans: 2;
    widows: 2;
    text-align: left
}

.privacy_c5 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 10pt;
    font-family: "Poppins";
    font-style: normal
}

.privacy_c8 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
}

.privacy_c23 {
    border-spacing: 0;
    border-collapse: collapse;
    margin-right: auto;
    width: 100%;
}

.privacy_c20 {
    -webkit-text-decoration-skip: none;
    color: #1155cc;
    text-decoration: underline;
    text-decoration-skip-ink: none
}

.privacy_c21 {
    background-color: #ffffff;
    max-width: 80%;
    padding: 120pt 72pt 72pt 72pt;
    place-self: center;
}

.privacy_c12 {
    font-size: 10pt;
    font-family: "Poppins";
    font-weight: 400
}

.privacy_c14 {
    font-size: 10pt;
    font-family: "Poppins";
    font-weight: 700
}

.privacy_c9 {
    color: inherit;
    text-decoration: inherit
}

.privacy_c18 {
    height: auto;
    padding:20px 0px;
 
}

.privacy_c10 {
    height: auto;
    padding:20px 0px;
}

.privacy_c19 {
    height: auto;
    padding:20px 0px;
}

.privacy_c22 {
    height: auto;
    padding:20px 0px;
}

.privacy_c15 {
    height: 12pt
}

.privacy_c17 {
    height: auto;
    padding:20px 0px;
}

.privacy_title {
    padding-top: 0pt;
    color: #17365d;
    border-bottom-color: #4f81bd;
    border-bottom-width: 1pt;
    font-size: 26pt;
    padding-bottom: 4pt;
    font-family: "Calibri";
    line-height: 1.0;
    border-bottom-style: solid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.privacy_subtitle {
    padding-top: 18pt;
    color: #666666;
    font-size: 24pt;
    padding-bottom: 4pt;
    font-family: "Georgia";
    line-height: 1.0;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
}

li {
    color: #000000;
    font-size: 12pt;
    font-family: "Times"
}

p {
    margin: 0;
    color: #000000;
    font-size: 12pt;
    font-family: "Times"
}

h1 {
    padding-top: 0pt;
    color: #ffffff;
    font-weight: 700;
    font-size: 14pt;
    padding-bottom: 0pt;
    font-family: "Arial";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: center
}

h2 {
    padding-top: 0pt;
    color: #ffffff;
    font-weight: 700;
    font-size: 11pt;
    padding-bottom: 0pt;
    font-family: "Arial";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h3 {
    padding-top: 12pt;
    -webkit-text-decoration-skip: none;
    font-weight: 700;
    font-size: 10pt;
    padding-bottom: 3pt;
    line-height: 1.0;
    page-break-after: avoid;
    text-decoration-skip-ink: none;
    font-family: "Arial";
    orphans: 2;
    widows: 2;
    text-align: left
}

h4 {
    padding-top: 0pt;
    -webkit-text-decoration-skip: none;
    color: #000000;
    font-weight: 700;
    text-decoration: underline;
    font-size: 10pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    page-break-after: avoid;
    font-style: italic;
    text-decoration-skip-ink: none;
    font-family: "Arial";
    orphans: 2;
    widows: 2;
    text-align: justify
}

h5 {
    padding-top: 12pt;
    color: #000000;
    font-weight: 700;
    font-size: 13pt;
    padding-bottom: 3pt;
    font-family: "Times New Roman";
    line-height: 1.0;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
}

h6 {
    padding-top: 10pt;
    color: #000000;
    font-weight: 700;
    font-size: 10pt;
    padding-bottom: 2pt;
    font-family: "Times";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}