ul.lst-kix_36gg77nqs6o9-4 {
    list-style-type: none
}

ul.lst-kix_36gg77nqs6o9-5 {
    list-style-type: none
}

ul.lst-kix_36gg77nqs6o9-2 {
    list-style-type: none
}

.lst-kix_36gg77nqs6o9-3>li:before {
    content: "\0025cf   "
}

ul.lst-kix_36gg77nqs6o9-3 {
    list-style-type: none
}

ul.lst-kix_36gg77nqs6o9-0 {
    list-style-type: none
}

.lst-kix_36gg77nqs6o9-5>li:before {
    content: "\0025a0   "
}

ul.lst-kix_36gg77nqs6o9-1 {
    list-style-type: none
}

.lst-kix_36gg77nqs6o9-6>li:before {
    content: "\0025cf   "
}

.lst-kix_36gg77nqs6o9-2>li:before {
    content: "\0025a0   "
}

ul.lst-kix_36gg77nqs6o9-8 {
    list-style-type: none
}

li.li-bullet-0:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt
}

.lst-kix_36gg77nqs6o9-4>li:before {
    content: "\0025cb   "
}

ul.lst-kix_36gg77nqs6o9-6 {
    list-style-type: none
}

ul.lst-kix_36gg77nqs6o9-7 {
    list-style-type: none
}

.lst-kix_36gg77nqs6o9-7>li:before {
    content: "\0025cb   "
}

.lst-kix_36gg77nqs6o9-1>li:before {
    content: "\0025cb   "
}

.lst-kix_36gg77nqs6o9-8>li:before {
    content: "\0025a0   "
}

.lst-kix_36gg77nqs6o9-0>li:before {
    content: "\0025cf   "
}

ol {
    margin: 0;
    padding: 0
}

table td,
table th {
    padding: 0
}

.c0 {
    background-color: #ffffff;
    padding-top: 0px;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left;
    height: 12pt
}

.c17 {
    margin-left: 36pt;
    padding-top: 0pt;
    padding-bottom: 12pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left;
    height: 12pt
}

.c14 {
    margin-left: 36pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 12pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c3 {
    color: #1fd2db;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 15pt;
    font-family: "Poppins";
    font-style: normal
}

.c2 {
    background-color: #ffffff;
    padding-top: 0pt;
    padding-bottom: 12pt;
    line-height: 1.5;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c6 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left;
    height: 12pt
}

.c9 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: "Times";
    font-style: normal
}

.c1 {
    background-color: #ffffff;
    padding-top: 5px;
    padding-bottom: 15px;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c4 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: "Poppins";
    font-style: normal
}

.c5 {
    -webkit-text-decoration-skip: none;
    color: #1155cc;
    font-weight: 700;
    text-decoration: underline;
    text-decoration-skip-ink: none;
    font-size: 10pt;
    font-family: "Poppins"
}

.c11 {
    color: #000000;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 10pt;
    font-style: normal
}

.c15 {
    color: #1fd2db;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-style: normal
}

.c16 {
    -webkit-text-decoration-skip: none;
    color: #1fd2db;
    text-decoration: underline;
    text-decoration-skip-ink: none;
    font-size: 10pt
}

.c7 {
    background-color: #ffffff;
    max-width: 80%;
    padding: 120pt 72pt 72pt 72pt;
    place-self: center;
}

.c13 {
    padding: 0;
    margin: 0
}

.c12 {
    font-weight: 400;
    margin-top: 15px;
    font-family: "Poppins"
}

.c8 {
    font-weight: 700;
    line-height: 2.5;
    font-family: "Poppins"
}

.c10 {
    color: inherit;
    text-decoration: inherit
}

.c18 {
    font-size: 10pt
}

.title {
    padding-top: 0pt;
    color: #17365d;
    border-bottom-color: #4f81bd;
    border-bottom-width: 1pt;
    font-size: 26pt;
    padding-bottom: 4pt;
    font-family: "Calibri";
    line-height: 1.0;
    border-bottom-style: solid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.subtitle {
    padding-top: 18pt;
    color: #666666;
    font-size: 24pt;
    padding-bottom: 4pt;
    font-family: "Georgia";
    line-height: 1.0;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
}

li {
    color: #000000;
    font-size: 12pt;
    font-family: "Times"
}

p {
    margin: 0;
    color: #000000;
    font-size: 12pt;
    font-family: "Times"
}

h1 {
    padding-top: 0pt;
    color: #ffffff;
    font-weight: 700;
    font-size: 14pt;
    padding-bottom: 0pt;
    font-family: "Arial";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: center
}

h2 {
    padding-top: 0pt;
    color: #ffffff;
    font-weight: 700;
    font-size: 11pt;
    padding-bottom: 0pt;
    font-family: "Arial";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h3 {
    padding-top: 12pt;
    -webkit-text-decoration-skip: none;
    font-weight: 700;
    font-size: 10pt;
    padding-bottom: 3pt;
    line-height: 1.0;
    page-break-after: avoid;
    text-decoration-skip-ink: none;
    font-family: "Arial";
    orphans: 2;
    widows: 2;
    text-align: left
}

h4 {
    padding-top: 0pt;
    -webkit-text-decoration-skip: none;
    color: #000000;
    font-weight: 700;
    text-decoration: underline;
    font-size: 10pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    page-break-after: avoid;
    font-style: italic;
    text-decoration-skip-ink: none;
    font-family: "Arial";
    orphans: 2;
    widows: 2;
    text-align: justify
}

h5 {
    padding-top: 12pt;
    color: #000000;
    font-weight: 700;
    font-size: 13pt;
    padding-bottom: 3pt;
    font-family: "Times New Roman";
    line-height: 1.0;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
}

h6 {
    padding-top: 10pt;
    color: #000000;
    font-weight: 700;
    font-size: 10pt;
    padding-bottom: 2pt;
    font-family: "Times";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}