.top-part{
    background-image: url('../imgs/banner.png');
}
.banner{
    background-image: url('../imgs/banner-bg.png');
    width: 100%;
    height: 100%;
    
}
.cta-button{
    max-width: 347px;
    width: 100%;
    max-height: 55px;
    height: 100%;
    background-color: #1FD2DB;
    box-shadow: 0px 4px 0px 0px #29215B;
    border-radius: 33px;
    -webkit-border-radius: 33px;
    -moz-border-radius: 33px;
    -ms-border-radius: 33px;
    -o-border-radius: 33px;
    color:#fff;
}
.cta-button:hover{
    background-color: #29215B;
    box-shadow: 0px 4px 0px 0px #1FD2DB;
}
.cta-button:focus{
    background: #1FD2DB;

}
.second-button{
    max-width: 216px;
    width: 100%;
    max-height: 55px;
    height: 100%;
    background-color: #29215B;
    box-shadow: 0px 4px 0px 0px #1FD2DB;
    border-radius: 33px;
    -webkit-border-radius: 33px;
    -moz-border-radius: 33px;
    -ms-border-radius: 33px;
    -o-border-radius: 33px;
    color:#fff;
}
.second-button:hover{
    background-color: #1FD2DB;
    box-shadow: 0px 4px 0px 0px #29215B;
}
.second-button:focus{
    background: #29215B;

}
.arrow-icon {
    max-height: 20px; /* Adjust size of the icon */
    width: auto;
}
.middle-bg{
    max-height: 542px;
    height: 100%;
    background-color: #DEFDFF;
    border-top-right-radius: 300px;
    border-bottom-right-radius: 300px;
    margin-right: 75px;
}
.middle-bg-sp{
    width: 100%;
    height: 100%;
    background-color: #DEFDFF;
    margin-right: 75px;
    border-radius: 181.5px;
    align-items: center;

}
.blogs{
    max-width: 297px !important;
    height: 297px !important;
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 33px 0px #00000017;
    padding:80px;
    border:1px solid #fff;
    border-top-left-radius: 43px;
    border-top-right-radius: 43px;
    border-bottom-right-radius: 43px;
    border-bottom-left-radius: 0px;
}
.footer{
    width: 100%;
    max-height:336px ;
    height: 100%;
    background-color: #F2F5F8;
    border-top-left-radius: 60px;
    border-top-right-radius: 60px;
    padding:91px 30px 30px 91px  !important;
}
.footer-sp{
    width: 100%;
    height: 100%;
    background-color: #F2F5F8;
    border-top-left-radius: 60px;
    border-top-right-radius: 60px;
}
.bottom-bar{
    height: 38.4px;
    width: 100%;
    background-color: #697DA4;
    color: #fff;

}
@media (max-width: 768px) {
    .bottom-bar{
        height: 78.4px;
    }
}
.header{
    height: 104px;
    width: 100%;
    position: fixed;
    background-color: #1FD2DB;
    border-bottom-left-radius: 23px;
    border-bottom-right-radius: 23px;
}
.header-sp{
    height: 61px;
    width: 100%;
    position: fixed;
    background-color: #1FD2DB;
    border-bottom-left-radius: 23px;
    border-bottom-right-radius: 23px;
}

.wp-block-group > .wp-block-group:first-child{
    visibility: hidden;
}
.header-btn:hover{
    color:#2A215B;
}
@media (max-width: 768px) {
    #simple-popover{
        background: #697DA454;
    }
}