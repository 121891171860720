@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-green {
  color: #1FD2DB;
}

.main-blue {
  color: #29215B;
}

.main-yellow {
  color: #F9BC04;
}

.main-grey {
  color: #697DA4;
}

.main-white {
  color: #fff;
}
.main-hover{
  color:#2A215B;
}
.font-12-900 {
  font-family: 'Lato';
  font-size: 13px;
  font-weight: 900;
  line-height: 25px;
  letter-spacing: 0.03em;
  text-align: left;
}

.font-12-500 {
  font-family: 'Lato';
  font-size: 13px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.03em;
  text-align: left;
}

.font-13-300 {
  font-family: 'Lato';
  font-size: 13px;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: 0.03em;
  text-align: center;
}

.font-13-400 {
  font-family: 'Roboto';
  font-size: 13px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.03em;
  text-align: center;
}


.font-13-500 {
  font-family: 'Lato';
  font-size: 13px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0.03em;
  text-align: center;

}
.font-14-500 {
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 1px;
}
.font-14-700 {
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 1px;
  text-align: center;
}

.font-16-400 {
  font-family: 'Lato';
  font-size: 16px;
  font-weight: 400;
  line-height: 33px;
  letter-spacing: 0.5px;

}
@media (min-width: 768px) {
  .font-16-400 {
    font-size: 18px;
    font-weight: 600;
    text-align: left;
  }
}
.font-16-500 {
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0.03em;
  text-align: center;
}

.font-18-400 {
  font-family: 'Poppins';
  font-size: 18px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0.5px;
  text-align: left;
}

.font-18-900 {
  font-family: 'Lato';
  font-size: 18px;
  font-weight: 900;
  line-height: 55px;
  letter-spacing: 1px;
  text-align: left;

}
.font-21-600{
  font-family: 'Poppins';
font-size: 21px;
font-weight: 600;
line-height: 29px;
letter-spacing: 1px;
text-align: center;

}
.font-22-700 {
  font-family: 'Lato';
  font-size: 22px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0.03em;
  text-align: center;
}

.font-24-600 {
  font-family: 'Poppins';
  font-size: 24px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0.03em;
  text-align: center;
}

.font-29-600 {
  font-family: 'Poppins';
  font-size: 29px;
  font-weight: 600;
  line-height: 39px;
  letter-spacing: 1px;
  text-align: left;
}

.font-29-700 {
  font-family: 'Poppins';
  font-size: 29px;
  font-weight: 700;
  line-height: 39px;
  letter-spacing: 1px;
  text-align: left;
}

.font-31-500 {
  font-family: 'Poppins';
  font-size: 35px;
  font-weight: 500;
  line-height: 35px;
  letter-spacing: 1px;
  text-align: center;
  
}
@media (min-width: 768px) {
  .font-31-500 {
    font-size: 55px;
    line-height: 63px;
    text-align: left;
  }
}
.font-55-500 {
  font-family: 'Poppins';
  font-size: 55px;
  font-weight: 500;
  line-height: 63px;
  letter-spacing: 1px;
  text-align: left;
}